/*
  Generates absolute and relative paths.
  Relative paths good to use in routing and absolute in Link component.

  Pay attentions that Original should be dynamic type to generate the right result structure.

  From:

  {
    register: { path: 'register', name: 'register' },
    profile: {
      root: { path: 'profile', name: 'profile' },
      orders: { path: 'orders', name: 'profile orders' },
      rates: {
        root: { path: 'rates', name: 'profile rates' },
        perfumes: { path: 'perfumes', name: 'profile perfume rates' },
        colognes: { path: 'colognes', name: 'profile cologne rates' },
      },
    },
  }

  Creates:

  {
    register: '/register',
    profile: {
      root: '/profile',
      orders: '/profile/orders',
      rates: {
        root: '/profile/rates',
        perfumes: '/profile/rates/perfumes',
        colognes: '/profile/rates/colognes',
      },
    },
  }

 */
const generateAbsoluteLinks = <Original extends Links.Links, Result extends Links.AbsoluteLinks<Original>>(links: Original, rootPath: string = ''): Result => {
  const map = (link: Links.Link | Links.Links, key: string) => {
    if ('path' in link) {
      if (key === 'root') {
        return rootPath
      }

      return `${rootPath}/${link.path}`
    }

    if (!link.root) {
      throw new Error('Missed `root` property in links object')
    }

    return generateAbsoluteLinks(link, `${rootPath}/${link.root.path}`)
  }

  return Object.keys(links).reduce((result, key) => {
    result[key] = map(links[key], key)

    return result
  }, {}) as Result
}


export default generateAbsoluteLinks

const generateRouteAnalytics = (links: Links.Links, rootPath: string = '', rootAnalytics?: Links.Analytics): Links.RouteAnalytics => (
  Object.keys(links).reduce((result, key) => {
    const link = links[key]

    if ('path' in link) {
      const analytics = {
        ...rootAnalytics,
        ...link.analytics,
      }

      if (key === 'root') {
        result[rootPath] = analytics
      }
      else {
        result[`${rootPath}/${link.path}`] = analytics
      }

      return result
    }

    const { root } = link

    if (!root) {
      throw new Error('Missed `root` property in links object')
    }

    return {
      ...result,
      ...generateRouteAnalytics(link, `${rootPath}/${root.path}`, root.analytics),
    }
  }, {})
)


export default generateRouteAnalytics

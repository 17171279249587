import { compile } from 'path-to-regexp'


type Params = {
  [key: string]: string | number | boolean
}

const cache = {}

const getLinkWithParams = (link: string, params?: Params, encode = false) => {
  if (!cache[link]) {
    cache[link] = compile(link, {
      encode: encode ? encodeURIComponent : undefined,
    })
  }

  return cache[link](params)
}


export default getLinkWithParams

import base from './links'
import generateAbsoluteLinks from './generateAbsoluteLinks'
import generateRouteAnalytics from './generateRouteAnalytics'
import generateRouteSitemap from './generateRouteSitemap'


export { default as getLinkWithParams } from './getLinkWithParams'

export const routeAnalytics = generateRouteAnalytics(base)

export const routeSitemap = generateRouteSitemap(base)

// ATTN it's required for auto-import
const links = generateAbsoluteLinks(base)

export default links

const generateRouteSitemap = (links: Links.Links, rootPath: string = '', rootSitemap?: Links.Sitemap): Links.RouteSitemap => (
  Object.keys(links).reduce((result, key) => {
    const link = links[key]

    if ('path' in link) {

      if (link.sitemap) {
        result[`${rootPath}/${link.path}`] = {
          ...rootSitemap,
          ...link.sitemap,
        }
      }

      return result
    }

    const { root } = link

    if (!root) {
      throw new Error('Missed `root` property in links object')
    }

    return {
      ...result,
      ...generateRouteSitemap(link, `${rootPath}/${root.path}`, root.sitemap),
    }
  }, {})
)


export default generateRouteSitemap
